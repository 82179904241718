import React from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import cn from 'classnames'

import SEO from '../../components/seo'
import Section from '../../components/Common/Section'
import Container from '../../components/Common/Container'
import BannerSection from '../../components/BannerSection'
import Typography from '../../components/Common/Typography'
import NewsQuoteBlock from '../../components/NewsQuoteBlock'
import ProductButton from '../../components/Common/ProductButton'

import { useTranslations } from '../../hooks/use-translations'
import { PathEnum } from '../../types/pages'

import * as s from '../../pages/news.module.scss'

const MakingBimForConstructionSite: React.FC = () => {
  const images = useStaticQuery(imagesQuery)
  const { getPath } = useTranslations()

  return (
    <>
      <SEO
        title="Making bim for construction site"
        description="This is the success story of our team’s interaction with the Bastech company, where our team provided on-site BIM consulting services, created a digital twin of the construction using orthophotos, digital elevation models (DEMs) and point clouds, which allowed us to obtain up-to-date operational information about the work being performed on site."
      />
      <BannerSection
        imageDesktop={images.banner.childImageSharp.gatsbyImageData}
        imageMobile={images.bannerMobile.childImageSharp.gatsbyImageData}
        titleSize={60}
        title={`Making BIM as great\u00A0power\n of construction phase`}
        subTitle={`How to manage digital data flow on\u00A0site, make construction model and\u00A0control\u00A0project with help of\u00A0digital services`}
      />
      <Section>
        <Container>
          <Typography variant="body1" size={18}>
            {`Bastech company is an EPC contractor of\u00A0the\u00A0first factory in Fujairah, UAE to\u00A0manufacture Basalt Fiber Rebar. The\u00A0construction part is\u00A0one\u00A0of\u00A0the most important. As Bastech had BIM models as\u00A0a\u00A0result of the design stage, it was absolutely consistent to use and to have maximum value out of it on site. SIGNAX\u00A0team\u00A0helped a customer with it.`}
          </Typography>
          <div className={s.imageWrap}>
            <StaticImage
              width={720}
              src="../../assets/images/making-bim-for-construction-site/image-1.png"
              alt="Factory's construction model"
              title="Factory's construction model"
              placeholder="blurred"
            />
            <Typography variant="body2" color="gray">
              Fig. 1. Factory's construction model
            </Typography>
          </div>
          <NewsQuoteBlock
            avatar={images.Paul.childImageSharp.gatsbyImageData}
            name="Paul"
            position="Consultant Engineer"
            text="“Thanks to SIGNAX and the Autodesk Docs common data environment, all construction participants (contractors, designers, consultants) have unified and transparent information on any mobile device online. It allows us to follow the project progress and be sure of the final result”"
          />
          <Typography variant="h3" color="gray" size={32}>
            Case 1
          </Typography>
          <Typography variant="h4" size={24} mt={32}>
            {`Challenge: Coordination of\u00A0disciplines`}
          </Typography>
          <Typography variant="body1" size={18} mt={16}>
            {`Numerous changes in one discipline of\u00A0the\u00A0BIM model should be coordinated\u00A0in\u00A0the others to prevent outdated information in drawings and\u00A0also\u00A0prevent potential clashes inside a\u00A0single or between several disciplines.`}
          </Typography>
          <Typography variant="h4" size={24} mt={32}>
            Solution: BIM consulting on-site, use of Revit server
          </Typography>
          <Typography variant="body1" size={18} mt={16}></Typography>
          <Typography variant="body1" size={18} mt={16}>
            {`SIGNAX team helped to successfully encounter this problem by starting to\u00A0deliver their on-site BIM consulting services. From\u00A0the\u00A0very\u00A0first\u00A0day, they provided training to the client, which helped\u00A0them deeply understand aspects\u00A0of\u00A0Autodesk and SIGNAX software. The client was also introduced to\u00A0Navisworks, BIM 360, and SIGNAX Tools functions.`}
          </Typography>
          <Typography variant="body1" size={18} mt={16}>
            {`Furthermore, the construction BIM model\u00A0was developed and updated during\u00A0the whole stage. It helped to\u00A0speed\u00A0up the project development process and dramatically reduce collisions.\u00A0Additionally, deploying a\u00A0Revit\u00A0server enhanced collaboration within\u00A0the\u00A0team:\u00A0brought real-time synchronization of the BIM model, and\u00A0allowed multiple team members to\u00A0work\u00A0on different disciplines simultaneously, minimizing clashes and\u00A0maximizing productivity.`}
          </Typography>
          <Typography variant="body1" size={18} mt={16}>
            {`The combination of on-site BIM consulting\u00A0and using a Revit server proved\u00A0to\u00A0be a successful strategy in\u00A0overcoming the challenges associated\u00A0with a highly dynamic information flow, resulting in efficient and\u00A0timely project development.`}
          </Typography>
          <div className={s.imageWrap}>
            <StaticImage
              width={720}
              src="../../assets/images/making-bim-for-construction-site/image-2.png"
              alt="Clashes, visible in BIM model"
              title="Clashes, visible in BIM model"
              placeholder="blurred"
            />
            <Typography variant="body2" color="gray">
              Fig. 2. Clashes, visible in BIM model
            </Typography>
          </div>
          <Typography variant="h3" color="gray" size={32}>
            Case 2
          </Typography>
          <Typography variant="h4" size={24} mt={32}>
            Challenge: Masterplan drawings are not actual
          </Typography>
          <Typography variant="body1" size={18} mt={16}>
            {`The site’s landscaping and utilities were\u00A0carried out without an up\u2011to\u2011date master plan, the as\u2011built documentation\u00A0for\u00A0these works was\u00A0provided late, and the design decisions\u00A0were behind the construction\u00A0works. This problem led to\u00A0challenges in coordinating utilities and\u00A0landscaping, resulting in delayed decision\u2011making on the construction site. It\u00A0necessitated a significant amount of time to conduct site inspections and become acquainted with the existing conditions and construction updates.`}
          </Typography>
          <Typography variant="h4" size={24} mt={32}>
            Solution: Regular aerial drone surveys, panoramic capturing
          </Typography>
          <Typography variant="body1" size={18} mt={16}>
            {`Specialists of the Digital Survey Department\u00A0organized regular flights of\u00A0the\u00A0construction site with the\u00A0preparation of orthomosaics, Digital\u00A0Elevatio\u00A0Models (DEM), and points\u00A0clouds, which allowed up\u2011to\u2011date\u00A0and\u00A0operational information on\u00A0the\u00A0work performed on the site, increased the team’s understanding of\u00A0the\u00A0project’s current work and reduced\u00A0site inspections time by 40%.`}
          </Typography>
          <Typography variant="body1" size={18} mt={16}>
            {`Panoramic and drone pictures helped to\u00A0visualize construction progress for\u00A0meetings not only for the on-site team\u00A0but also for\u00A0meetings with\u00A0remote\u00A0teams.`}
          </Typography>
          <Typography variant="body1" size={18} mt={16}>
            {`Orthomosaics revealed inaccuracies in\u00A0the\u00A0current landscaping works and\u00A0corrected them before completion,\u00A0which shortened the time for\u00A0their reworks. Using orthomosaic has\u00A0made it possible to maintain a\u00A0current\u00A0operational plan of utilities and\u00A0networks.`}
          </Typography>
          <Typography variant="body1" size={18} mt={16}>
            {`Point clouds and DEMs made it possible to\u00A0calculate the volumes of dumps near\u00A0the\u00A0construction site, which allowed\u00A0more accurate planning of\u00A0material\u00A0procurement and production.`}
          </Typography>
          <Typography variant="h3" color="gray" size={32} mt={32}>
            Case 3
          </Typography>
          <Typography variant="h4" size={24} mt={32}>
            Challenge: Tracking works dynamics and project progress
          </Typography>
          <Typography variant="body1" size={18} mt={16}>
            {`Obtaining real-time and accurate data from\u00A0the\u00A0construction site for daily progress\u00A0reporting is a significant challenge. Data\u00A0collection\u00A0by\u00A0classic report\u00A0methods may lead to delays and\u00A0potential inaccuracies and not always\u00A0depict the visual nuances of\u00A0construction progress. Maintaining consistency and standardization in\u00A0daily\u00A0logs across various teams and subcontractors can\u00A0be\u00A0difficult.`}
          </Typography>
          <div className={s.imageWrap}>
            <StaticImage
              width={720}
              src="../../assets/images/making-bim-for-construction-site/image-3.png"
              alt="Tracking progress in real-time"
              title="Tracking progress in real-time"
              placeholder="blurred"
            />
            <Typography variant="body2" color="gray">
              Fig. 3. Tracking progress in real-time
            </Typography>
          </div>
          <Typography variant="h4" size={24}>
            {`Solution: 360\u2011degree photos by\u00A0SIGNAX INSPECTION software`}
          </Typography>
          <Typography variant="body1" size={18} mt={16}>
            {`Comprehensive Visual Documentation: 360\u2011degree imagery provides a\u00A0complete\u00A0visual record of\u00A0the\u00A0construction site from all angles. It\u00A0can\u00A0be invaluable for tracking progress,\u00A0identifying discrepancies, and\u00A0conducting virtual site inspections.`}
          </Typography>
          <Typography variant="body1" size={18} mt={16}>
            {`Enhanced Communication and Collaboration: sharing 360\u2011degree imagery\u00A0with stakeholders allows for\u00A0better\u00A0communication and\u00A0collaboration\u00A0with the help of\u00A0comments and issues features. All\u00A0parties\u00A0involved, regardless of their location, can virtually visit the site, enabling\u00A0clearer discussions and informed\u00A0decision\u2011making.`}
          </Typography>
          <Typography variant="body1" size={18} mt={16}>
            {`Improved Accuracy in Analysis: by\u00A0having\u00A0a\u00A0detailed and accurate visual\u00A0representation of\u00A0the\u00A0construction\u00A0site at various stages, the\u00A0project manager and team analyzed progress more effectively. The\u00A0BIM\u00A0model comparison feature aided\u00A0in\u00A0identifying potential issues early,\u00A0optimized workflows, and made data\u2011driven decisions to keep the\u00A0project\u00A0on\u00A0track.`}
          </Typography>
          <Typography variant="h3" color="gray" size={32} mt={32}>
            Case 4
          </Typography>
          <Typography variant="h4" size={24} mt={32}>
            {`Challenge: Difference between as\u2011built and shop drawings. Not\u00A0enough data for Digital Twin`}
          </Typography>
          <Typography variant="body1" size={18} mt={16}>
            {`One of the customer’s requirements was\u00A0the\u00A0development of a Digital Twin of\u00A0the\u00A0plant, for which it was necessary to\u00A0combine shop drawings, as\u2011built documentation, and real\u2011time sensor data. The survey of MEP systems inside the\u00A0building was not carried out; as\u2011built\u00A0documentation was developed without taking into consideration in\u2011place\u00A0changes and not enough data regarding rising pipes\u00A0and\u00A0duct elbows. As\u00A0a\u00A0result, the actual situation in\u00A0the\u00A0facilities differed from that in\u00A0the\u00A0drawings.`}
          </Typography>
          <div className={s.imageWrap}>
            <div
              className={cn(
                s.columnWrapper,
                'w-100 justify-between items-center'
              )}
              style={{ gap: 8 }}
            >
              <StaticImage
                width={538}
                src="../../assets/images/making-bim-for-construction-site/image-4.png"
                alt="Part of the interior point cloud"
                title="Part of the interior point cloud"
                placeholder="blurred"
              />
              <StaticImage
                width={538}
                src="../../assets/images/making-bim-for-construction-site/image-5.png"
                alt="Part of the interior point cloud"
                title="Part of the interior point cloud"
                placeholder="blurred"
              />
            </div>
            <Typography variant="body2" color="gray">
              Fig. 4. Part of the interior point cloud
            </Typography>
          </div>
          <Typography variant="h4" size={24} mt={32}>
            {`Solution: 360\u2011degree photos by SIGNAX INSPECTION software`}
          </Typography>
          <Typography variant="body1" size={18} mt={16}>
            {`SIGNAX surveyors performed two iterations of laser scanning of the facility. The\u00A0first iteration is performed at the moment of\u00A0installation of\u00A0MEP systems, the\u00A0second\u00A0iteration is done after completion of the architectural finishing.`}
          </Typography>
          <Typography variant="body1" size={18} mt={16}>
            {`Point clouds allowed BIM department specialists to model missing communications, and point clouds allowed\u00A0them to show the actual crossing\u00A0and position of MEP systems. Point\u00A0clouds data was stored on\u00A0the\u00A0SIGNAX\u00A0point cloud web\u2011viewer that\u00A0was able to use data for\u00A0all\u00A0project\u00A0stakeholders without installing specialized software to utilize point clouds for everyday work.`}
          </Typography>
          <Typography variant="body1" size={18} mt={16}>
            {`Another crucial result is the improvement\u00A0of\u00A0as\u2011built documentation\u00A0quality and the\u00A0accurate\u00A0calculation of\u00A0the\u00A0scope\u00A0of\u00A0work.`}
          </Typography>
          <Typography variant="h4" size={24} mt={32} mb={24} color="blue">
            Solutions and services used on this project
          </Typography>
          <div className={s.productLinksWrapper}>
            <Link to={getPath(PathEnum.Tools)}>
              <ProductButton title={`SIGNAX\nTools`} />
            </Link>
            <Link to={getPath(PathEnum.Inspection)}>
              <ProductButton title={`SIGNAX\nInspection`} />
            </Link>
            <Link to={getPath(PathEnum.Digital)}>
              <ProductButton title={`SIGNAX\nDigital services`} />
            </Link>
          </div>
        </Container>
      </Section>
    </>
  )
}

const imagesQuery = graphql`
  query {
    banner: file(
      relativePath: { eq: "making-bim-for-construction-site/banner.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, height: 516, placeholder: BLURRED)
      }
    }
    bannerMobile: file(
      relativePath: { eq: "making-bim-for-construction-site/banner-mobile.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    Paul: file(relativePath: { eq: "author/Paul.png" }) {
      childImageSharp {
        gatsbyImageData(width: 50, placeholder: BLURRED)
      }
    }
  }
`

export default MakingBimForConstructionSite
